import React, { useState } from 'react'
import Card from '../../../../components/Card/Card'
import styles from './OrganizationBidDetails.module.scss'
import OrganizationLayout from '../../../../components/OrganizationLayout/OrganizationLayout'
import { useParams, useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import OrganizationAPI from '../../../../api/OrganizationAPI'
import Loader from '../../../../components/Loader/Loader'
import AuthContext from '../../../../context/AuthContext'
import { useContext } from 'react'
import Table from '../../../../components/Table/Table'
import TableContent from '../../../../components/Table/TableContent/TableContent'
import TableButton from '../../../../components/Table/TableButton/TableButton'
import Button from '../../../../components/Button/Button'
import InputWithError from '../../../../components/InputWithError/InputWithError'
import moment from 'moment/moment'
import { toast } from 'react-toastify'
import Badge from '../../../../components/Badge/Badge'

const OrganizationBidDetails = () => {
    const { user } = useContext(AuthContext)
    const { bidHeaderId } = useParams()
    const navigate = useNavigate()

    const [comment, setComment] = useState('')
    const [tempData, setTempData] = useState([])

    const bidLinesQuery = useQuery(['bid-lines', bidHeaderId], () =>
        OrganizationAPI.getBidLines({ token: user.token, id: bidHeaderId })
    )
    const bidHeadersQuery = useQuery(['bid', bidHeaderId], () =>
        OrganizationAPI.getBidHeaderById({ token: user.token, bidHeaderId })
    )

    // const bidHeadersQueryTemp = useQuery(['bid-headers', bidHeaderId], () =>
    //     OrganizationAPI.getBidHeadersByAuctionId({ token: user.token, id: bidHeaderId })
    // )

    const bidHeaderData = bidHeadersQuery?.data?.data
    console.log('bid header datas:', bidHeaderData)

    // console.log('template datas:', typeof bidHeadersQuery)

    // console.log('Bid Headers Data:', bidHeadersQueryTemp.data)

    // const bidData = bidHeadersQueryTemp?.data?.data

    const AuctionLines = useQuery(
        ['auction-lines'],
        () => OrganizationAPI.getAuctionLines({ token: user.token, id: bidHeaderData?.auction_header?.id }),
        {
            enabled: !!bidHeaderData?.auction_header?.id,
        }
    )

    const bidHeaderComments = useQuery(
        ['auction-comments'],
        () => OrganizationAPI.getBidHeaderComments({ token: user.token, id: bidHeaderData?.id }),
        {
            enabled: !!bidHeaderData?.id,
            select: (data) => {
                return data.data.map((comment) => ({
                    created_at: moment(comment.created_at).format('MMMM DD, YYYY'),
                    create_by: comment.author,
                    message: comment.message,
                }))
            },
        }
    )

    const addBidHeaderComment = useMutation(
        (data) => OrganizationAPI.addBidHeaderComment({ token: user.token, id: bidHeaderData?.id, data }),
        {
            onSuccess: () => {
                toast.success('Comment added successfully')
                bidHeaderComments.refetch()
            },
            onError: () => {
                toast.error('Error adding comment')
            },
        }
    )

    const handleAward = async () => {
        if (!user.token) {
            toast.error('Authentication error: No token provided')
            return
        }

        try {
            const response = await OrganizationAPI.createPurchaseOrder(user.token, bidHeaderId)
            console.log('Purchase Order Created:', response)
            toast.success('Purchase order created successfully')
        } catch (error) {
            console.error('Error creating purchase order:', error)
            toast.error('Error creating purchase order')
        }
    }

    const formatDate = (dateString) =>
        new Date(dateString).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })
    const handleNavigate = () =>
        navigate(`/organization/supplier/details/${bidHeadersQuery?.data?.data?.organisation?.id}`)

    const auctionLinesData = AuctionLines?.data?.data
    console.log(auctionLinesData)
    const handlePost = (e) => {
        e.preventDefault()
        addBidHeaderComment.mutate({ message: comment })
        setComment('')
    }
    return (
        <OrganizationLayout active="rfq" title="Details" className={styles.OrganizationBidDetails}>
            <div className={styles.Request__header}>
                <h1></h1>
                <div className={styles.Request__buttons}>
                    {/* <div className={styles.Request__buttonWrapper}>
                        <Button text="Chat" color="#FFD73B" />
                    </div> */}
                    <div className={styles.Request__buttonWrapper}>
                        <Button text="Award" onClick={handleAward} />
                    </div>

                    <div className={styles.Request__buttonWrapper}>
                        <Button text="Ignore" color="rgba(255, 31, 0, 0.76)" />
                    </div>
                </div>
            </div>
            {bidLinesQuery.isLoading || bidHeadersQuery.isLoading ? (
                <Loader />
            ) : bidLinesQuery.isError || bidHeadersQuery.isError ? (
                <p className={styles.Request__errorText}>Error loading data.</p>
            ) : (
                <>
                    <div className={styles.Cards}>
                        <div className={styles.Cards__left}>
                            <Card className={styles.Request__bidCard}>
                                <div className={styles.Card} onClick={handleNavigate}>
                                    <p className={styles.bidNoteHead}>Quote By: {bidHeaderData?.organisation?.name}</p>
                                    <p className={styles.Details}>
                                        Address: {bidHeaderData?.organisation?.address_line1},{' '}
                                        {bidHeaderData?.organisation?.address_line2}
                                    </p>
                                    <p className={styles.Details}>Contact: {bidHeaderData?.organisation?.contact}</p>
                                </div>
                            </Card>
                            <Card className={styles.Request__bidCard}>
                                <p className={styles.bidNoteHead}>Bid Details</p>
                                <p className={styles.Details}>Bid No: {bidHeaderData?.bidders_bid_number}</p>
                                <p className={styles.Details}>
                                    Expiry Date: {formatDate(bidHeaderData?.bid_expiration_date)}
                                </p>
                                <p className={styles.Details}>
                                    Status:{' '}
                                    {bidHeaderData?.bid_status == 'draft' ? (
                                        <Badge
                                            value={bidHeaderData?.bid_status}
                                            color="#f7a64f"
                                            backgroundColor="#fdebd8"
                                        />
                                    ) : (
                                        <Badge
                                            value={bidHeaderData?.bid_status}
                                            color="#00B87C"
                                            backgroundColor="#DFF6F0"
                                        />
                                    )}
                                </p>
                            </Card>
                        </div>
                        <div className={styles.Cards__right}>
                            <Card>
                                <p className={styles.bidNoteHead}>Comments</p>
                                <div className={styles.timeline}>
                                    <div className={styles.timeline__Content}>
                                        {bidHeaderComments?.data?.map((item, index) => (
                                            <div className={styles.tlContent} key={index}>
                                                <div className={styles.tlHeader}>
                                                    <span className={styles.tlMarker}></span>
                                                    <div className={styles.tlAuthor}>
                                                        {item.create_by}
                                                        <time className={styles.tlTime} dateTime={item.created_at}>
                                                            {item.created_at}
                                                        </time>
                                                    </div>
                                                </div>
                                                <div className={styles.tlBody}>
                                                    <p>{item.message}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <p className={styles.Note}>
                                        <b>Note</b>: Avoid disclosing private information here.
                                    </p>
                                    <form className={styles.commentForm} onSubmit={handlePost}>
                                        <div className={styles.comment}>
                                            <InputWithError
                                                name={comment}
                                                placeholder={'Comment here'}
                                                type={'text'}
                                                value={comment}
                                                onChange={(e) => setComment(e.target.value)}
                                            />
                                        </div>
                                        <Button
                                            className={styles.commentBtn}
                                            text="Post"
                                            type="submit"
                                            loading={addBidHeaderComment.isLoading}
                                        />
                                    </form>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <Table>
                        <TableContent>
                            <thead>
                                <tr>
                                    <th>Sl No</th>
                                    <th>Product Name</th>
                                    <th>Quantity</th>
                                    <th>Brand</th>
                                    <th>Price Quoted</th>
                                    <th>Promised Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {auctionLinesData?.map((line, index) => {
                                    const correspondingBidLine = bidLinesQuery.data.data.find(
                                        (bidLine) => bidLine.auction_line === line.id
                                    )
                                    console.log(correspondingBidLine)
                                    return (
                                        <tr key={line.id}>
                                            <td>{index + 1}</td>
                                            <td>{line.product_name}</td>
                                            <td>{line.quantity}</td>
                                            <td>{line.brand}</td>
                                            <td>{correspondingBidLine ? correspondingBidLine.price : '-'}</td>
                                            <td>
                                                {correspondingBidLine
                                                    ? formatDate(correspondingBidLine.promised_date)
                                                    : '-'}
                                            </td>
                                            {correspondingBidLine ? (
                                                <TableButton>
                                                    {/* <Button text="Negotiate" /> */}
                                                </TableButton>
                                            ) : (
                                                <td>
                                                    <Badge
                                                        value={'Not Bidded'}
                                                        color="#FC555B"
                                                        backgroundColor="#FED7D8"
                                                    />
                                                </td>
                                            )}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </TableContent>
                    </Table>

                    <Card>
                        <div className={styles.Request__bidResponsesTitle}>
                            <h3 className={styles.Request__title}>Templates</h3>
                        </div>
                        <hr />

                        {bidHeaderData?.templates ? (
                            <>
                                <h4 className={styles.Request__descriptionTitle}>{bidHeaderData.templates.name}</h4>
                                <h4 className={styles.Request__text} style={{ textAlign: 'left' }}>
                                    {bidHeaderData.templates.display_description}
                                </h4>
                            </>
                        ) : (
                            <p className={styles.Request__text}>No templates available.</p>
                        )}
                    </Card>
                </>
            )}
        </OrganizationLayout>
    )
}

export default OrganizationBidDetails
