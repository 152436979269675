import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './OrganizationBidCompare.module.scss';
import AuthContext from '../../../../../context/AuthContext';
import OrganizationLayout from '@components/OrganizationLayout/OrganizationLayout';
import Table from '@components/Table1';
import OrganizationAPI from '../../../../../api/OrganizationAPI';
import { useQuery } from '@tanstack/react-query';

function isCellLowestInRow(item, cellValue, comparisonData) {
    const currentCellValue = parseFloat(cellValue);
    let isLowest = true;

    Object.keys(comparisonData[item]).forEach((seller) => {
        const otherCellValue = parseFloat(comparisonData[item][seller]);
        if (otherCellValue < currentCellValue) {
            isLowest = false;
        }
    });

    return isLowest;
}

function generateSerialNumbers(items) {
    return items.map((_, index) => index + 1);
}

function OrganizationBidCompare() {
    const navigate = useNavigate();

    const { auctionId } = useParams();
    const { user } = useContext(AuthContext);
    const [comparisonData, setComparisonData] = useState({});

    const fetchComparisonData = async () => {
        try {
            const { data } = await OrganizationAPI.compareBids({ token: user.token, auctionId });
            setComparisonData(data);
        } catch (error) {
            console.error('Error fetching comparison data:', error);
        }
    };

    const { data: bidDataResponse, isLoading, isError } = useQuery(['bid', auctionId], () =>
        OrganizationAPI.getBidHeadersByAuctionId({ token: user.token, id: auctionId })
    );

    useEffect(() => {
        fetchComparisonData();
    }, []);

    // Ensure bidData is an array or set it to an empty array as a fallback
    const bidData = Array.isArray(bidDataResponse?.data) ? bidDataResponse.data : [];

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error loading data</div>;
    }

    const extractedData = bidData.map((item) => ({
        id: item.id,
        name: item.organisation.name,
    }));

    const navigateToBids = (seller) => {
        const sellerData = extractedData.find((item) => item.name === seller);
        if (sellerData) {
            navigate(`/organization/rfq/detail/bids/${sellerData.id}`);
        }
    };

    let sellers = {};
    let items = [];
    if (Object.keys(comparisonData).length > 0) {
        items = Object.keys(comparisonData);

        items.forEach((item) => {
            Object.keys(comparisonData[item]).forEach((seller) => {
                sellers[seller] = true;
            });
        });
    }

    const uniqueSellers = Object.keys(sellers);
    const serialNumbers = generateSerialNumbers(items);

    return (
        <OrganizationLayout title="Compare Bids" active="rfq">
            <Table>
                {items.length === 0 ? (
                    <div className={styles.empty_records}>Not enough data to be displayed </div>
                ) : (
                    <Table.Content>
                        <thead>
                            <tr>
                                <th>SL No</th>
                                <th>Items</th>
                                {uniqueSellers.map((seller, index) => (
                                    <th key={index}>{seller}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item, index) => (
                                <tr key={index}>
                                    <td>{serialNumbers[index]}</td>
                                    <td>{item}</td>
                                    {uniqueSellers.map((seller, innerIndex) => {
                                        const cellValue = comparisonData[item][seller] || 0;
                                        const isLowestValue = isCellLowestInRow(item, cellValue, comparisonData);

                                        return (
                                            <td
                                                key={innerIndex}
                                                className={isLowestValue ? styles['lowest-value'] : ''}
                                                onClick={() => navigateToBids(seller)}
                                            >
                                                {cellValue}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </Table.Content>
                )}
            </Table>
        </OrganizationLayout>
    );
}

export default OrganizationBidCompare;
