import styles from './SupplierProductCatalogue.module.scss'
import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react'
import Button from '../../components/Button/Button'
import AuthContext from '../../context/AuthContext'
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import { useNavigate } from 'react-router-dom'
import Table from '../../components/Table/Table'
import TableContent from '../../components/Table/TableContent/TableContent'
import { useQuery } from '@tanstack/react-query'
import SupplierAPI from '../../api/SupplierAPI'
import Badge from '../../components/Badge/Badge'
import Loader from '../../components/Loader/Loader'
import TableControls from '../../components/Table/TableControls/TableControls'
import { HiViewGrid, HiOutlineViewList } from 'react-icons/hi'
import Card from '../../components/Card/Card'
import {
    IoNotificationsCircle,
    IoNotificationsCircleSharp,
    IoNotificationsOffSharp,
    IoNotificationsSharp,
    IoShareSocialSharp,
} from 'react-icons/io5'
import { toast } from 'react-toastify'
import TableControlSearch from '../../components/Table/TableControlSearch/TableControlSearch'
import OrganizationCard from '../OrganizationDashboardRFQ/OrganizationCard/OrganizationCard'
import SeaIcon from '../../assets/icons/sea.svg'
import FormMultiSelect from '../../components/FormMultiSelect/FormMultiSelect'
import ProductAPI from '../../api/ProductAPI'

const SupplierProductCatalogue = () => {
    const [searchText, setSearchText] = useState('')

    const { user } = useContext(AuthContext)

    const [dropdownState, setDropdownState] = useState({})
    const [selectedCategories, setSelectedCategories] = useState([])

    const navigate = useNavigate()

    const [showDeletedMessage, setShowDeletedMessage] = useState(false)

    const [isDeleting, setIsDeleting] = useState(false)

    // const filteredData = useMemo(() => filteredTableData(productListQuery.data, searchText), [productListQuery.data, searchText]);

    const filteredTableData = (data, text) => {
        let filteredData = data
            ? data.filter((item) => {
                  var values = Object.values(item)
                  var pattern = new RegExp(text, 'i')
                  return values.some((value) => pattern.test(value))
              })
            : []

        return filteredData.map((item, index) => ({
            ...item,
            slNo: index + 1,
        }))
    }
    const productListQuery = useQuery(['product', 'product-list'], () => SupplierAPI.getProductList(user.token), {
        select: (data) => {
            console.log('Product list data:', data)
            const products = data?.data.inventory || []
            const categoriesSet = new Set()

            const productsWithAdditionalInfo = products.map((product, index) => {
                categoriesSet.add(product.product_sub_category)
                return {
                    id: product.id,
                    slNo: index + 1,
                    name: product.name,
                    price: product.list_price_per_unit,
                    email: product.email,
                    product_category: product.product_sub_category,
                    status: '',
                    enquiry: '',
                    dropdown: false,
                }
            })

            return {
                products: productsWithAdditionalInfo,
                uniqueCategoriesCount: categoriesSet.size,
                enquiryCount: data?.data.enquiry_count || 0,
            }
        },
    })
    const productCategoriesQuery = useQuery(
        ['product-categories'],
        () =>
            ProductAPI.getProductCategories({
                token: user.token,
            }),
        {
            select: (data) => {
                const categories = {}
                data?.data.forEach((category) => (categories[category.name] = category.slug))
                return categories
            },
        }
    )

    const handleCategoriesChange = (value) => {
        setSelectedCategories(value)
    }
    const filteredData = useMemo(
        () =>
            productListQuery.data?.products.filter((product) => {
                if (selectedCategories.length === 0) return true
                return selectedCategories.includes(product.product_category)
            }),
        [productListQuery.data, selectedCategories]
    )

    console.log('filteredData:', filteredData)

    const handleSearchChange = (value) => {
        setSearchText(value)
    }

    const handleShare = () => {
        // on clicking this btn a link should be copied to clipboard
        navigator.clipboard.writeText(`${window.location.origin}/products/${user.organisation}`)
        toast.success('Link copied')
    }

    const toggleDropdown = useCallback((id) => {
        setDropdownState((prevState) => {
            const newState = {}
            Object.keys(prevState).forEach((key) => {
                newState[key] = false
            })
            newState[id] = !prevState[id]
            return newState
        })
    }, [])

    const closeDropdowns = useCallback(() => {
        setDropdownState({})
    }, [])

    var [viewMode, setViewMode] = useState('list')

    var toggleViewMode = () => {
        setViewMode((prevMode) => (prevMode === 'list' ? 'gallery' : 'list'))
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest(`.${styles.Table__dropdown}`)) {
                closeDropdowns()
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [closeDropdowns])

    const handleViewProduct = (id) => navigate(`/supplier/products/${id}`)

    const handleDelete = async (id) => {
        setIsDeleting(true)
        try {
            await SupplierAPI.deleteProductById({ token: user.token, id })
            productListQuery.refetch()
            setShowDeletedMessage(true)
        } catch (error) {
            console.error('Error deleting product:', error)
        } finally {
            setIsDeleting(false)
        }
    }

    const handleMakeAdmin = (id) => {}

    const ListTableBody = React.memo(({ data }) => (
        <>
            <thead>
                <tr>
                    <th>SL No</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Product Category</th>
                    <th>Enquiry</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {productListQuery.isLoading || productListQuery.isError ? (
                    <tr>
                        <td colSpan="7">
                            <Loader height="30vh" refresh={productListQuery.isError} />
                        </td>
                    </tr>
                ) : productListQuery.data?.length === 0 ? (
                    <tr>
                        <td colSpan="7" style={{ textAlign: 'center' }}>
                            No products found
                        </td>
                    </tr>
                ) : (
                    data?.map((row) => (
                        <tr key={row.id} onClick={() => handleViewProduct(row.id)}>
                            <td>{row.slNo}</td>
                            {['name', 'price', 'product_category', 'enquiry', 'status'].map((field) => (
                                <td key={field}>{row[field]}</td>
                            ))}
                            <td
                                className={styles.Table__dropdownContainer}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    toggleDropdown(row.id)
                                }}
                            >
                                <button className={styles.Table__dropdownButton}>&#8226;&#8226;&#8226;</button>
                                {dropdownState[row.id] && (
                                    <ul className={styles.Table__dropdown}>
                                        <li
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                handleDelete(row.id)
                                            }}
                                        >
                                            Delete
                                        </li>
                                        {/* <li
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                handleMakeAdmin(row.id)
                                            }}
                                        >
                                            Make admin
                                        </li> */}
                                    </ul>
                                )}
                            </td>
                        </tr>
                    ))
                )}
            </tbody>
        </>
    ))

    const GalleryView = React.memo(({ data, handleViewProduct }) => (
        <div className={styles.galleryContainer}>
            {data.map((row) => (
                <Card
                    className={styles.galleryItem}
                    key={row.id}
                    onClick={() => {
                        console.log('Clicked on gallery item:', row.id)
                        handleViewProduct(row.id)
                    }}
                >
                    <div className={styles.galleryItemHeader}>
                        <div className={styles.galleryItemTitle}>{row.name}</div>
                        <div className={styles.galleryItemPrice}>${row.price}</div>
                    </div>
                    <div className={styles.galleryItemDetails}>{row.product_category}</div>
                </Card>
            ))}
        </div>
    ))
    return (
        <SupplierLayout title="Product Catalogue" active="products">
            <div className={styles.Cards}>
                <div className={styles.Cards__card}>
                    <OrganizationCard
                        icon={SeaIcon}
                        color="#5E81F41A"
                        name="Products"
                        value={productListQuery.data?.products.length || 0}
                    />
                </div>
                <div className={styles.Cards__card}>
                    <OrganizationCard
                        icon={SeaIcon}
                        color="#5E81F41A"
                        name="Product Category"
                        value={productListQuery.data?.uniqueCategoriesCount || 0}
                    />
                </div>
                <div className={styles.Cards__card}>
                    <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="Product Catalogue" value="0" />
                </div>
            </div>
            <FormMultiSelect
                data={productCategoriesQuery?.data || []}
                name="categories"
                placeholder="Select Categories"
                type="text"
                label={'Filter by Category'}
                onChange={(e) => handleCategoriesChange(e.target.value)}
                value={selectedCategories} // Pass the selected options
            />
            <Table>
                <TableControls>
                    <div className={styles.Products__top}>
                        <div className={styles.Products__rightContainer}>
                            <Button text="Export CSV" color="#0E2C24" />
                            <span className={styles.Products__view} onClick={toggleViewMode}>
                                {viewMode === 'gallery' ? <HiOutlineViewList size={20} /> : <HiViewGrid size={20} />}
                            </span>
                            <Button text={<IoShareSocialSharp />} color="#0047ab" onClick={handleShare} />
                            <div className={styles.EnquiryButtonContainer}>
                                <Button
                                    text={<IoNotificationsSharp />}
                                    onClick={() => navigate(`/supplier/enquiries/notifications/${user.organisation}`)}
                                />
                                {productListQuery.data?.enquiryCount > 0 && (
                                    <Badge
                                        value={productListQuery.data.enquiryCount}
                                        backgroundColor="#e74c3c"
                                        color="#fff"
                                        className={styles.EnquiryBadge}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={styles.Products__rightContainer}>
                            <TableControlSearch onSearchChange={handleSearchChange} />
                            <Button text="Add a Product" onClick={() => navigate('/supplier/products/add')} />
                        </div>
                    </div>
                </TableControls>
                {productListQuery.isError || productListQuery.isLoading ? (
                    <Loader refresh={productListQuery.isError} height="30vh" />
                ) : (
                    <TableContent hoverable>
                        {isDeleting && (
                            <tr>
                                <td colSpan="7">
                                    <Loader />
                                </td>
                            </tr>
                        )}

                        {showDeletedMessage && (
                            <tr>
                                <td colSpan="7">
                                    <div className={styles.deletedMessage}>
                                        Product deleted successfully!
                                        <span
                                            className={styles.closeButton}
                                            onClick={() => setShowDeletedMessage(false)}
                                        >
                                            x
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        )}

                        {viewMode === 'list' ? (
                            <ListTableBody data={filteredData} />
                        ) : (
                            <GalleryView data={filteredData} handleViewProduct={handleViewProduct} />
                        )}
                    </TableContent>
                )}
            </Table>
        </SupplierLayout>
    )
}

export default SupplierProductCatalogue
