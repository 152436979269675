import axios from 'axios'

class OrganizationAPI {
    static async getDashboardData({ token }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/dashboard/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async createOrganization({ token, data }) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/organisations/`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async deleteFocalPoint({ token, id }) {
        return await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/auction-header/focal-point/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }
    f

    static async createAuctionHeader({ token, data }) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auctions/`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async updateAuctionHeader({ token, data, headerId }) {
        return await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/auctions/${headerId}`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async deleteAuctionHeaderAttachment({ token, headerId }) {
        return await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/auction-header/attachment/${headerId}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async deleteAuctionLineAttachment({ token, headerId }) {
        return await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/auction-lines/attachment/${headerId}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async createAuctionLine({ token, data, headerId }) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auctions/${headerId}/auction-lines`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }
    static async UpdateAuctionLine({ token, data, headerId }) {
        return await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/auction-lines/${headerId}`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async DeleteAuctionLine({ token, headerId }) {
        return await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/auction-lines/${headerId}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getMyAuctions(token, pNo) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/my-auctions/?page=${pNo}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getPurchaseOrder(token) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/purchase-orders/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getPurchaseOrderById({ token, id }) {
        console.log('base url:', process.env.REACT_APP_API_BASE_URL)
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/purchase-orders/${id}/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async printPDF({ token, id }) {
        console.log('base url:', process.env.REACT_APP_API_BASE_URL)
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/purchase-orders/${id}/`, null, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
            responseType: 'blob',
        })
        return response.data
    }

    static async createPurchaseOrder(token, id) {
        return await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/create-purchase-order/${id}/`,
            {},
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
            }
        )
    }

    static async getAuctionById({ token, id }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auctions/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getIncoterms({ token }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/list-incoterms/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async inviteSupplier({ token, data }) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/organisations/invite-client`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async inviteClient({ token, data }) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/organisations/invite/`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getOrganization(token) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/organisations/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getOrganizationStaffs({ token, id }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/organisations/${id}/staffs/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async createOrganizationStaff({ token, id, data }) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/organisations/${id}/staffs/`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getOrganizationStaff({ token, id }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async editOrganizationStaff({ token, orgId, data, userId }) {
        return await axios.patch(
            `${process.env.REACT_APP_API_BASE_URL}/organisations/${orgId}/staffs/${userId}/`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Token ' + token,
                },
            }
        )
    }

    static async editOrganizationStaffPassword({ token, orgId, data, userId }) {
        return await axios.patch(
            `${process.env.REACT_APP_API_BASE_URL}/organisations/change-password/org/${orgId}/user/${userId}/`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Token ' + token,
                },
            }
        )
    }

    static async getOrganizationById({ token, id }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/organisations/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async updateOrganizationById({ token, id, data }) {
        return await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/organisations/${id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getAuctionById({ token, id }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auctions/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getAuctionLines({ token, id }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auctions/${id}/auction-lines`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getAuctionComments({ token, id }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auctions/${id}/comments`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async addAuctionComment({ token, id, data }) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auctions/${id}/comments`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getBidHeaderComments({ token, id }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/bids/${id}/comments`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }
    static async addBidHeaderComment({ token, id, data }) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/bids/${id}/comments`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    // static async getAuctionLines({ token, id }) {
    //     return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auctions/${id}/auction-lines`, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: 'Token ' + token,
    //         },
    //     })
    // }

    static async getAllSellers(token) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/organisations/sellers/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async addAuthorizedSeller({ token, orgId, data }) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/organisations/${orgId}/add-seller`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getAuthorizedSellers({ token, orgId }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/organisations/${orgId}/authorised-sellers`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getBidLines({ token, id }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/bids/${id}/bid-lines`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getBidHeadersByAuctionId({ token, id }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auctions/${id}/bids`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getBidHeaderById({ token, bidHeaderId }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/bids/${bidHeaderId}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getBidbyId({ token }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auctions/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getBidsById({ token, id }) {
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/bids/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }
    static async createBidHeader({ token, id }) {
        // console.log({ data })
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auctions/${id}/bids`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async compareBids({ token, auctionId }) {
        // console.log({ data })
        return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auctions/${auctionId}/compare-bids`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async changeProfilePassword({ token, data }) {
        return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/change-password/`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Token ' + token,
            },
        })
    }

    static async getActivityLog({ token, viewAll }) {
        return await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/activity-logs/buyers/${viewAll ? '?all=true' : ''}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Token ' + token,
                },
            }
        )
    }
}

export default OrganizationAPI
