import React, { useState, useEffect } from 'react'

import Card from '../../components/Card/Card'
import styles from './OrganizationSupplierDetailsNew.module.scss'
import OrganizationLayout from '../../components/OrganizationLayout/OrganizationLayout'
import Loader from '../../components/Loader/Loader'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import AuthContext from '../../context/AuthContext'
import { useContext } from 'react'
import SupplierAPI from '../../api/SupplierAPI'
import OrganizationAPI from '../../api/OrganizationAPI'
import { IoShareSocialSharp } from 'react-icons/io5'
import OrganizationCard from '../OrganizationDashboardRFQ/OrganizationCard/OrganizationCard'
import SeaIcon from '../../assets/icons/sea.svg'
import Table from '../../components/Table/Table'
import TableContent from '../../components/Table/TableContent/TableContent'
import { toast } from 'react-toastify'
import Button from '../../components/Button/Button'

const OrganizationSupplierDetailsNew = () => {
    const navigate = useNavigate()
    // const { auctionId } = useParams()
    const { id } = useParams()
    const { user } = useContext(AuthContext)
    const detailsQuery = useQuery(['my-auctions'], () => SupplierAPI.getSupplierDetails({ token: user.token, id: id }))
    const purchaseOrderDetailsQuery = useQuery(['purchase-order-details', id], () =>
        SupplierAPI.getPurchaseOrderDetails({ token: user.token, id })
    )
    const countQuery = useQuery(['supplier-action-count', id], () =>
        SupplierAPI.getSupplierActionCountDetails({ token: user.token, id })
    )
    const documentDetailsQuery = useQuery(['supplier-document-details', id], () =>
        SupplierAPI.getSupplierDocumentDetails({ token: user.token, id })
    )

    const supplierBidDetailsQuery = useQuery(['supplier-bid-details', id], () =>
        SupplierAPI.getSupplierBidDetails({ token: user.token, id })
    )

    const handleNavigateToProducts = () => {
        navigate(`/supplier/details/products/${id}`) 
    }

    if (
        detailsQuery.isError ||
        purchaseOrderDetailsQuery.isError ||
        countQuery.isError ||
        documentDetailsQuery.isError ||
        supplierBidDetailsQuery.isError
    ) {
        return <Loader />
    }

    if (
        detailsQuery.isLoading ||
        purchaseOrderDetailsQuery.isLoading ||
        countQuery.isLoading ||
        documentDetailsQuery.isLoading ||
        supplierBidDetailsQuery.isLoading
    ) {
        return <Loader />
    }

    const details = detailsQuery.data?.data
    const bids = supplierBidDetailsQuery.data?.data || []
    const purchaseOrderDetails = purchaseOrderDetailsQuery.data?.data?.purchase_order_document_nums || []
    const documentDetails = documentDetailsQuery.data?.data?.attachments || []
    const actionCount = countQuery.data?.data || {}
    return (
        <OrganizationLayout active="supplier" title="Details">
            <>
                <div className={styles.topCard}>
                    <span className={styles.organizationNameTop}>Name : {detailsQuery.data?.data?.name}</span>
                  
                        <span className={styles.organizationCode}>Code : {detailsQuery.data?.data?.code}</span>
                        </div>
                        <Button className={styles.catalogue} text=" View Product Catalogue"  onClick={handleNavigateToProducts} />
                   
                
                <div className={styles.Cards}>
                    <div className={styles.Cards__card}>
                        <OrganizationCard
                            icon={SeaIcon}
                            color="#5E81F41A"
                            name="Purchase Orders"
                            value={actionCount.purchase_order_count || 0}
                        />
                    </div>
                    <div className={styles.Cards__card}>
                        <OrganizationCard
                            icon={SeaIcon}
                            color="#5E81F41A"
                            name="Bids"
                            value={actionCount.bid_count || 0}
                        />
                    </div>
                </div>
                <div className={styles.details}>
                    <div className={styles.Card}>
                        <p className={styles.Request__organizationDetails}>
                            <span className={styles.Request__organizationName}>Supplier Details</span>
                        </p>
                        <div className={styles.Request__organizationContents}>
                            <p className={styles.Request__text}>
                                <span>Name</span>
                                <span>{details?.name}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Email</span>
                                <span>{details?.email}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Contact</span>
                                <span>{details?.contact}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Address</span>
                                <span>{details?.address_line1},{details?.address_line2}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>City</span>
                                <span>{details?.city}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>State</span>
                                <span>{details?.state}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Country</span>
                                <span>{details?.country}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Postal Code</span>
                                <span>{details?.postal_code}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Default currency</span>
                                <span>{details?.default_currency}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Number of Employees</span>
                                <span>{details?.number_of_employees}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Total Turnover</span>
                                <span>{details?.total_turnover}</span>
                            </p>
                        </div>
                    </div>
                    {/* <div className={styles.Card}>
                            <p className={styles.Request__organizationDetails}>
                                <span className={styles.Request__organizationName}>Contact Details</span>
                            </p>
                            <div className={styles.Request__organizationContents}>
                                <p className={styles.Request__text}>
                                    <span>Department Mail</span>
                                    <span>{detailsQuery.data?.data?.name}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Telephone Line</span>
                                    <span>{detailsQuery.data?.data?.email}</span>
                                </p>

                                <p className={styles.Request__text}>
                                    <span>Founder's Name</span>
                                    <span>{detailsQuery.data?.data?.contact}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Founder's Email</span>
                                    <span>{detailsQuery.data?.data?.address_line_1}</span>
                                </p>
                                <p className={styles.Request__text}>
                                    <span>Founder's Phone</span>
                                    <span>{detailsQuery.data?.data?.city}</span>
                                </p>
                            </div>
                        </div> */}

                    <br />
                </div>
                <div className={styles.table}>
                    <div className={styles.topTable}>
                        <Table>
                            <span className={styles.tableHeading}>RFQ Details</span>
                            <TableContent>
                                <thead>
                                    <tr>
                                        <th>SL No</th>
                                        <th>Title</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bids.length > 0 ? (
                                        bids.map((bid, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{bid.auction_header?.title}</td>
                                                <td></td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3" style={{ textAlign: 'center' }}>
                                                No data found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </TableContent>
                        </Table>
                        <Table>
                            <span className={styles.tableHeading}>Purchase Order Details</span>
                            <TableContent>
                                <thead>
                                    <tr>
                                        <th>SL No</th>
                                        <th>Title</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {purchaseOrderDetails.length > 0 ? (
                                        purchaseOrderDetails.map((order, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{order}</td>
                                                <td></td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3" style={{ textAlign: 'center' }}>
                                                No data found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </TableContent>
                        </Table>
                    </div>
                    <div className={styles.bottomTable}>
                        <Table>
                            <span className={styles.tableHeading}>Bid Details</span>
                            <TableContent>
                                <thead>
                                    <tr>
                                        <th>SL No</th>
                                        <th>Name</th>
                                        <th>Code</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bids.length > 0 ? (
                                        bids.map((bid, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{bid.organisation?.name}</td>
                                                <td>{bid.organisation?.code}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3" style={{ textAlign: 'center' }}>
                                                No data found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </TableContent>
                        </Table>
                        <Table>
                            <span className={styles.tableHeading}> Document Details</span>
                            <TableContent>
                                <thead>
                                    <tr>
                                        <th>SL No</th>
                                        <th>Title</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {documentDetails.length > 0 ? (
                                        documentDetails.map((attachment, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{attachment.name}</td>
                                                <td>{attachment.file}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3" style={{ textAlign: 'center' }}>
                                                No data found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </TableContent>
                        </Table>
                    </div>
                </div>
            </>
        </OrganizationLayout>
    )
}

export default OrganizationSupplierDetailsNew
