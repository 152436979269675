import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment/moment'
import OrganizationLayout from '../../components/OrganizationLayout/OrganizationLayout'
import Button from '../../components/Button/Button'
import Table from '../../components/Table/Table'
import TableControls from '../../components/Table/TableControls/TableControls'
import TableContent from '../../components/Table/TableContent/TableContent'
import TableControlSearch from '../../components/Table/TableControlSearch/TableControlSearch'
import Loader from '../../components/Loader/Loader'
import Filter from '../../components/Filter/Filter'
import OrganizationAPI from '../../api/OrganizationAPI'
import AuthContext from '../../context/AuthContext'
import SeaIcon from '../../assets/icons/sea.svg'
import OrganizationCard from './OrganizationCard/OrganizationCard'
import styles from './OrganizationDashboardRFQ.module.scss'
import Badge from '../../components/Badge/Badge'
import Pagination from '../../components/Pagination/Pagination'

const OrganizationDashboardRFQ = () => {
    const [searchInput, setSearchInput] = useState('')
    const [filter, setFilter] = useState('All')
    const [pNo, setPNo] = useState(1)
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()

    const [lastUpdatedSortOrder, setLastUpdatedSortOrder] = useState('desc')
    const [activeSort, setActiveSort] = useState('Replies')
    const [dueDateSortOrder, setDueDateSortOrder] = useState('desc')

    const getAuctionsQuery = useQuery(['get-auctions', pNo], () => OrganizationAPI.getMyAuctions(user.token, pNo), {
        select: (data) => {
            // Extract count and results from the response data
            const { count, results } = data?.data

            // Map the results as before
            const mappedResults = results
                ?.map((auction) => ({
                    Title: auction.title,
                    Reference: auction.requisition_number,
                    Date: moment(auction.created_at).format('DD/MM/YYYY'),
                    'Due Date': moment(auction.need_by_date).format('DD/MM/YYYY'),
                    Replies: auction.bid_count,
                    'Last Updated': moment(auction.updated_at).format('DD/MM/YYYY'),
                    Status: auction.status,
                    id: auction.id,
                }))
                .sort((a, b) => moment(b.Date, 'DD/MM/YYYY').valueOf() - moment(a.Date, 'DD/MM/YYYY').valueOf())

            // Return both count and mapped results
            return { count, results: mappedResults }
        },
        enabled: pNo !== undefined,
    })

    const [sortOrder, setSortOrder] = useState('desc')
    const draftCount = getAuctionsQuery.data?.results?.filter((auction) => auction.Status === 'draft').length || 0
    const completedCount = getAuctionsQuery.data?.results?.filter((auction) => auction.Status === 'completed').length || 0

    const handleSortChange = (column) => {
        setActiveSort(column)
        if (column === 'Replies') {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
        } else if (column === 'Last Updated') {
            setLastUpdatedSortOrder(lastUpdatedSortOrder === 'asc' ? 'desc' : 'asc')
        } else if (column === 'Due Date') {
            setDueDateSortOrder(dueDateSortOrder === 'asc' ? 'desc' : 'asc')
        }
    }

    const filteredData = () => {
        const rawData = getAuctionsQuery?.data?.results || []
        let filteredAndSortedData = rawData.filter((row) => {
            const matchesFilter = filter === 'All' || row.Status.toLowerCase() === filter.toLowerCase()
            const matchesSearch = !searchInput || row.Title.toLowerCase().includes(searchInput.toLowerCase())
            return matchesFilter && matchesSearch
        })

        filteredAndSortedData.sort((a, b) => {
            if (activeSort === 'Replies') {
                return sortOrder === 'asc' ? a.Replies - b.Replies : b.Replies - a.Replies
            } else if (activeSort === 'Last Updated') {
                return lastUpdatedSortOrder === 'asc'
                    ? moment(a['Last Updated'], 'DD/MM/YYYY').diff(moment(b['Last Updated'], 'DD/MM/YYYY'))
                    : moment(b['Last Updated'], 'DD/MM/YYYY').diff(moment(a['Last Updated'], 'DD/MM/YYYY'))
            } else if (activeSort === 'Due Date') {
                return dueDateSortOrder === 'asc'
                    ? moment(a['Due Date'], 'DD/MM/YYYY').diff(moment(b['Due Date'], 'DD/MM/YYYY'))
                    : moment(b['Due Date'], 'DD/MM/YYYY').diff(moment(a['Due Date'], 'DD/MM/YYYY'))
            }
        })

        return filteredAndSortedData
    }

    const handleSearchChange = (value) => setSearchInput(value)

    const handleFilterChange = (value) => setFilter(value)

    const navigateToDetailPage = (auctionId, status) => {
        navigate(`/organization/rfq/${status === 'draft' ? 'edit' : 'detail'}/${auctionId}`)
    }
    const handleNavigate = (i) => {
        setPNo(i)
    }

    return (
        <OrganizationLayout active="rfq" title="RFQ">
            <div className={styles.Rfq__container}>
                <div className={styles.Cards}>
                    <div className={styles.Cards__card}>
                        <OrganizationCard
                            icon={SeaIcon}
                            color="#5E81F41A"
                            name="All"
                            value={getAuctionsQuery.data?.count || 0}
                        />
                    </div>

                    <div className={styles.Cards__card}>
                        <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="Accepted" value= {completedCount}/>
                    </div>
                    <div className={styles.Cards__card}>
                        <OrganizationCard icon={SeaIcon} color="#5E81F41A" name="Draft" value={draftCount} />
                    </div>
                </div>
                <br />
                <Table>
                    <TableControls>
                        <div className={styles.Rfq__tableControls}>
                            <div className={styles.Rfq__tableControlsLeft}>
                                <Button text="Export CSV" color="#0E2C24" />
                                <Filter
                                    filter={filter}
                                    options={['All', 'In-Progress', 'Draft', 'Completed']}
                                    onChange={handleFilterChange}
                                />
                            </div>
                            <div className={styles.Rfq__row}>
                                <TableControlSearch onSearchChange={handleSearchChange} />
                                <Button
                                    text="Create New RFQ"
                                    onClick={() => navigate('/organization/rfq/create_new')}
                                />
                            </div>
                        </div>
                    </TableControls>
                    <TableContent hoverable={!(getAuctionsQuery.isLoading || getAuctionsQuery.isError)}>
                        <thead>
                            <tr>
                                <th>SL No</th>
                                <th>Title</th>
                                <th>Reference</th>
                                <th>Date</th>
                                <th className={styles.sortableHeader} onClick={() => handleSortChange('Due Date')}>
                                    Due Date
                                    <span className={styles.sortIndicator}>
                                        {dueDateSortOrder === 'asc' && activeSort === 'Due Date' ? '↑' : '↓'}
                                    </span>
                                </th>

                                {/* <th>Due Date</th> */}

                                {/* <th className={styles.sortableHeader} onClick={handleSortChange}>
                                        Replies
                                        <span className={styles.sortIndicator}>{sortOrder === 'asc' ? '↑' : '↓'}</span>
                                    </th>
                                    <th>Last Updated</th> */}
                                <th className={styles.sortableHeader} onClick={() => handleSortChange('Replies')}>
                                    Replies
                                    <span className={styles.sortIndicator}>
                                        {sortOrder === 'asc' && activeSort === 'Replies' ? '↑' : '↓'}
                                    </span>
                                </th>
                                <th className={styles.sortableHeader} onClick={() => handleSortChange('Last Updated')}>
                                    Last Updated
                                    <span className={styles.sortIndicator}>
                                        {lastUpdatedSortOrder === 'asc' && activeSort === 'Last Updated' ? '↑' : '↓'}
                                    </span>
                                </th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getAuctionsQuery.isLoading || getAuctionsQuery.isError ? (
                                <tr>
                                    <td colSpan="8">
                                        <Loader height="40vh" refresh={getAuctionsQuery.isError} />
                                    </td>
                                </tr>
                            ) : filteredData().length === 0 ? (
                                <tr>
                                    <td colSpan="8" style={{ textAlign: 'center' }}>
                                        No data found
                                    </td>
                                </tr>
                            ) : (
                                filteredData().map((row, index) => (
                                    <tr key={row.id} onClick={() => navigateToDetailPage(row.id, row.Status)}>
                                        <td>{index + 1}</td>
                                        {Object.entries(row)
                                            .filter(([key]) => key !== 'id')
                                            .map(([key, value], columnIndex) => (
                                                <td key={columnIndex}>
                                                    {key === 'Status' ? (
                                                        <>
                                                            {value.toLowerCase() === 'in-progress' && (
                                                                <Badge
                                                                    value={value}
                                                                    color="#1BBB6B"
                                                                    backgroundColor="#EDF8F3"
                                                                />
                                                            )}
                                                            {value.toLowerCase() === 'draft' && (
                                                                <Badge
                                                                    value={value}
                                                                    color="#f7a64f"
                                                                    backgroundColor="#fdebd8"
                                                                />
                                                            )}
                                                            {value.toLowerCase() === 'completed' && (
                                                                <Badge
                                                                    value={value}
                                                                    color="#FC555B"
                                                                    backgroundColor="#FED7D8"
                                                                />
                                                            )}
                                                        </>
                                                    ) : (
                                                        value
                                                    )}
                                                </td>
                                            ))}
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </TableContent>
                </Table>
                <Pagination handleChange={handleNavigate} count={getAuctionsQuery?.data?.count} currentPage={pNo} />
            </div>
        </OrganizationLayout>
    )
}

export default OrganizationDashboardRFQ
