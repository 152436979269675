import React, { useEffect, useState } from 'react'
import Card from '../../components/Card/Card'
import Button from '../../components/Button/Button'
import styles from './PurchaseOrderDetails.module.scss'
import { useParams } from 'react-router-dom'
import OrganizationAPI from '../../api/OrganizationAPI'
import AuthContext from '../../context/AuthContext'
import { useContext } from 'react'
import OrganizationLayout from '../../components/OrganizationLayout/OrganizationLayout'
import { useQuery } from '@tanstack/react-query'
import Loader from '../../components/Loader/Loader'
import TableButton from '../../components/Table/TableButton/TableButton'
import Table from '../../components/Table/Table'
import TableContent from '../../components/Table/TableContent/TableContent'
import { FaEye } from 'react-icons/fa'
import moment from 'moment/moment'

const PurchaseOrderDetails = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false)
    const { id } = useParams()
    const [orderDetails, setOrderDetails] = useState(null)
    const { user } = useContext(AuthContext)
    const fetchOrderDetails = useQuery(
        ['purchaseOrderDetails', id],
        () => OrganizationAPI.getPurchaseOrderById({ token: user.token, id }),
        {
            enabled: !!id,
            onSuccess: (data) => {
                console.log(data)
                setOrderDetails(data?.data)
            },
        }
    )
    console.log(orderDetails)

    

    const showPopup = () => {
        setIsPopupVisible(true)
    }

    const hidePopup = () => {
        setIsPopupVisible(false)
    }

    return (
        <OrganizationLayout title="Purchase Order Details" active="orders">
            {fetchOrderDetails.isLoading ? (
                <Loader />
            ) : fetchOrderDetails.isError ? (
                <Loader refresh />
            ) : (
                <>
                    <Card>
                        <div className={styles.Request__titleContainer}>
                            <p className={styles.Request__title}>Auction Details</p>
                        </div>

                        <Card>
                            <p className={styles.Request__text}>
                                <span>Title:</span>
                                <span>{orderDetails?.bid_header_details?.auction_header?.title??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Requisition Number:</span>
                                <span>{orderDetails?.bid_header_details?.auction_header?.requisition_number??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Product Category:</span>
                                <span>{orderDetails?.bid_header_details?.auction_header?.product_category??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Product Sub Category:</span>
                                <span>{orderDetails?.bid_header_details?.auction_header?.product_sub_category??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Need by Date:</span>
                                <span>
                                {orderDetails?.bid_header_details?.auction_header?.need_by_date
                                        ? moment(orderDetails?.bid_header_details?.auction_header?.need_by_date).format('DD/MM/YYYY')
                                        : 'N/A'}
                                </span>
                            </p>
                        </Card>
                        <br />
                    </Card>

                    <Card>
                        <div className={styles.Request__titleContainer}>
                            <p className={styles.Request__title}>Supplier Details</p>
                        </div>

                        <Card>
                            <p className={styles.Request__text}>
                                <span>Name:</span>
                                <span>{orderDetails?.supplier_organisation_details?.name??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Address:</span>
                                <span>{`${orderDetails?.supplier_organisation_details?.address_line1}, ${orderDetails?.supplier_organisation_details?.address_line2}`}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>City:</span>
                                <span>{orderDetails?.supplier_organisation_details?.city??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Country:</span>
                                <span>{orderDetails?.supplier_organisation_details?.country??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Postal Code:</span>
                                <span>{orderDetails?.supplier_organisation_details?.postal_code??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Email:</span>
                                <span>{orderDetails?.supplier_organisation_details?.email??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Contact:</span>
                                <span>{orderDetails?.supplier_organisation_details?.contact??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>State:</span>
                                <span>{orderDetails?.supplier_organisation_details?.state??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Total Turnover:</span>
                                <span>{orderDetails?.supplier_organisation_details?.total_turnover??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>No of Employees:</span>
                                <span>{orderDetails?.supplier_organisation_details?.number_of_employees??'N/A'}</span>
                            </p>
                        </Card>
                        <br />
                    </Card>

                    <Card>
                        <div className={styles.Request__titleContainer}>
                            <p className={styles.Request__title}>Organization Details</p>
                        </div>

                        <Card>
                            <p className={styles.Request__text}>
                                <span>Name:</span>
                                <span>{orderDetails?.buyer_organisation_details?.name??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Address:</span>
                                <span>{`${orderDetails?.buyer_organisation_details?.address_line1}, ${orderDetails?.buyer_organisation_details?.address_line2}`}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>City:</span>
                                <span>{orderDetails?.buyer_organisation_details?.city??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Country:</span>
                                <span>{orderDetails?.buyer_organisation_details?.country??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Postal Code:</span>
                                <span>{orderDetails?.buyer_organisation_details?.postal_code??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Email:</span>
                                <span>{orderDetails?.buyer_organisation_details?.email??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Contact:</span>
                                <span>{orderDetails?.buyer_organisation_details?.contact??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>State:</span>
                                <span>{orderDetails?.buyer_organisation_details?.state??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Total Turnover:</span>
                                <span>{orderDetails?.buyer_organisation_details?.total_turnover??'N/A'}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>No of Employees:</span>
                                <span>{orderDetails?.buyer_organisation_details?.number_of_employees??'N/A'}</span>
                            </p>
                        </Card>
                        <br />
                    </Card>

                    {/* <Card>
                        <div className={styles.Request__titleContainer}>
                            <p className={styles.Request__title}>Items</p>
                        </div>
                        <Card>
                            {orderDetails.details?.map((detail, index) => (
                                <div key={index}>
                                    <p className={styles.Request__text}>
                                        <span>Product Name:</span>
                                        <span>{detail.product_name}</span>
                                    </p>
                                    <p className={styles.Request__text}>
                                        <span>Quantity:</span>
                                        <span>{detail.quantity}</span>
                                    </p>
                                    <p className={styles.Request__text}>
                                        <span>Price:</span>
                                        <span>{detail.price}</span>
                                    </p>
                                    <p className={styles.Request__text}>
                                        <span>Promised Date:</span>
                                        <span>{formatDate(detail.bid_line_details?.promised_date)}</span>
                                    </p>
                                    <p className={styles.Request__text}>
                                        <span>Quantity:</span>
                                        <span>{detail.bid_line_details?.bid_quantity}</span>
                                    </p>
                                </div>
                            ))}
                        </Card>
                    </Card> */}

                    <Card>
                        <div className={styles.Request__bidResponsesTitle}>
                            <span className={styles.Request__title}>Items</span>
                        </div>

                        <Table>
                            <TableContent>
                                <thead>
                                    <tr>
                                        <th>SL No</th>
                                        <th>Name</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th>Promised Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderDetails?.items?.length>0?(
                                        orderDetails?.items?.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.product_name}</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.price}</td>
                                            <td>
                                                {moment(item.bid_line_items?.promised_date).format('DD/MM/YYYY')}
                                            </td>
                                        </tr>
                                   ))
                                ) : (
                                    <tr>
                                        <td colSpan="5">No items found</td>
                                    </tr>
                                )}
                            </tbody>
                            </TableContent>
                        </Table>
                    </Card>

                    <Card>
                        <div className={styles.Request__titleContainer}>
                            <p className={styles.Request__title}>Bid Details</p>
                        </div>

                        <Card>
                            <p className={styles.Request__text}>
                                <span>Bidders Bid Number:</span>
                                <span>{orderDetails?.bid_header_details?.bidders_bid_number}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Bid Status:</span>
                                <span>{orderDetails?.bid_header_details?.bid_status}</span>
                            </p>

                            <p className={styles.Request__text}>
                                <span>Status:</span>
                                <span>{orderDetails?.bid_header_details?.auction_header?.status}</span>
                            </p>
                            <p className={styles.Request__text}>
                                <span>Bid Count:</span>
                                <span>{orderDetails?.bid_header_details?.auction_header?.bid_count}</span>
                            </p>
                        </Card>
                        <br />
                        <div className={styles.Request__buttonWrapper}>
                            <Button text="See More" onClick={showPopup} />
                        </div>
                    </Card>

                    {orderDetails?.bid_header_details?.auction_header?.attachments && (
                        <Card>
                            <div className={styles.Request__bidResponsesTitle}>
                                <span className={styles.Request__title}>Attachments</span>
                            </div>

                            <Table>
                                <TableContent>
                                    <thead>
                                        <tr>
                                            <th>SL No</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>File</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderDetails?.bid_header_details?.auction_header?.attachments.map(
                                            (attachment, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{attachment.name}</td>
                                                    <td>{attachment.description}</td>
                                                    <TableButton>
                                                        <FaEye
                                                            onClick={() =>
                                                                window.open(
                                                                    process.env.REACT_APP_API_BASE_URL + attachment.file
                                                                )
                                                            }
                                                        />
                                                    </TableButton>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </TableContent>
                            </Table>
                        </Card>
                    )}

<Card>
    <div className={styles.Request__titleContainer}>
        <p className={styles.Request__title}>Template Details</p>
    </div>

    {orderDetails?.bid_header_details?.templates ? (
        <Card>
            <p className={styles.Request__text}>
                <span>Template Name:</span>
                <span>{orderDetails.bid_header_details.templates.name}</span>
            </p>
            <p className={styles.Request__text}>
                <span>Description:</span>
                <span>{orderDetails.bid_header_details.templates.display_description}</span>
            </p>
        </Card>
    ) : (
        <Card>
            <p className={styles.Request__text}>No templates selected</p>
        </Card>
    )}
    <br />
</Card>

                    <br />
                    {isPopupVisible && (
                        <div className={styles.Request__Popup}>
                            <div className={styles.Request__Popup__content} onClick={(e) => e.stopPropagation()}>
                                <Card>
                                    <div className={styles.Request__titleContainer}>
                                        <p className={styles.Request__title}> Additional Details</p>
                                    </div>
                                    <Card>
                                        <div className={styles.Request__Popup__items}>
                                            <p className={styles.Request__text}>
                                                <span>Bidders Bid Number:</span>
                                                <span>{orderDetails?.bid_header_details?.bidders_bid_number}</span>
                                            </p>
                                            <p className={styles.Request__text}>
                                                <span>Bid Status:</span>
                                                <span>{orderDetails?.bid_header_details?.bid_status}</span>
                                            </p>
                                            <p className={styles.Request__text}>
                                                <span>Response Type:</span>
                                                <span>{orderDetails?.bid_header_details?.type_of_response}</span>
                                            </p>
                                            <p className={styles.Request__text}>
                                                <span>Bid Expiration Date:</span>
                                                <span>
                                                    {moment(
                                                        orderDetails?.bid_header_details?.bid_expiration_date
                                                    ).format('DD/MM/YYYY')}
                                                </span>
                                            </p>
                                            <p className={styles.Request__text}>
                                                <span>Supplier Note:</span>
                                                <span>{orderDetails?.bid_header_details?.note_to_supplier}</span>
                                            </p>

                                            <p className={styles.Request__text}>
                                                <span>Status:</span>
                                                <span>{orderDetails?.bid_header_details?.auction_header?.status}</span>
                                            </p>
                                            <p className={styles.Request__text}>
                                                <span>Bid Count:</span>
                                                <span>
                                                    {orderDetails?.bid_header_details?.auction_header?.bid_count}
                                                </span>
                                            </p>
                                        </div>
                                    </Card>
                                    <Button className={styles.closeButton} text="Close" onClick={hidePopup} />
                                </Card>
                            </div>
                        </div>
                    )}
                </>
            )}
        </OrganizationLayout>
    )
}

export default PurchaseOrderDetails
