import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import styles from './SupplierProductCatalogueAdd.module.scss'
import cx from 'classnames'
import InputWithError from '../../components/InputWithError/InputWithError'
import FormTextarea from '../../components/FormTextarea/FormTextarea'
import FormSelect from '../../components/FormSelect/FormSelect'
import { useContext, useState } from 'react'
import Button from '../../components/Button/Button'
import ProductAPI from '../../api/ProductAPI'
import AuthContext from '../../context/AuthContext'
import { toast } from 'react-toastify'
import SupplierAPI from '../../api/SupplierAPI'
import { useMutation, useQuery } from '@tanstack/react-query'
import Loader from '../../components/Loader/Loader'
import { useNavigate } from 'react-router-dom'
import Card from '../../components/Card/Card'

const SupplierProductCatalogueAdd = () => {
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()

    const [form, setForm] = useState({
        additional_attributes: [],
        base_item_id: '123',
        is_available: true,
        is_inventory_item: true,
        is_service_item: true,
        market_price: 50,
        unit_of_issue: 100,
        documents: [],
        images: [],
    })
    const [formErrors, setFormErrors] = useState({})

    const productCatgoriesQuery = useQuery(
        ['product-categories'],
        () =>
            ProductAPI.getProductCategories({
                token: user.token,
            }),
        {
            select: (data) => {
                const categories = {}
                data?.data.forEach((category) => (categories[category.name] = category.slug))
                return categories
            },
        }
    )

    const productSubCategoriesQuery = useQuery(
        ['product-subcategories', form.product_category],
        () =>
            ProductAPI.getProductSubCategories({
                token: user.token,
                category: form.product_category,
            }),
        {
            select: (data) => {
                const categories = {}
                data.data?.forEach((category) => (categories[category.name] = category.slug))
                return categories
            },
            enabled: !!form.product_category,
        }
    )

    const submitMutation = useMutation(SupplierAPI.addProduct, {
        onSuccess: () => {
            toast.success('Added Product')
            navigate('/supplier/products')
        },
        onError: (err) => {
            if (err.response.status === 400) {
                return setFormErrors(err.response.data)
            }
            return toast.error(err.response.data?.message)
        },
    })

    const handleChange = (e) => {
        const { name, value } = e.target

        setForm((prev) => {
           
            if (name === 'product_category') {
                return { ...prev, [name]: value, product_sub_category: '' }
            }

           
            return { ...prev, [name]: value }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        // const formData = new FormData(e.target)

        // formData.append('base_item_id', '123')
        // formData.append('is_available', true)
        // formData.append('is_inventory_item', true)
        // formData.append('is_service_item', true)
        // formData.append('market_price', 50)
        // formData.append('unit_of_issue', 100)
        // // formData.append('documents[]', [])
        // // formData.append('additional_attributes[]', [])
        // form.images.forEach((file, index) => {
        //     formData.append('images[]', file)
        // })

        // console.log(Object.fromEntries(formData))

        console.log({ form })
        submitMutation.mutate({
            token: user.token,
            data: form,
        })
    }

    // const handleImageChange = async (e) => {
    //     // const file = e.target.files[0]
    //     // const reader = new FileReader()
    //     // reader.readAsDataURL(file)
    //     // reader.onload = () => {
    //     //     const base64String = reader.result.replace('data:', '').replace(/^.+,/, '')
    //     //     setForm((prev) => {
    //     //         return {
    //     //             ...prev,
    //     //             images: [{ image: base64String }],
    //     //         }
    //     //     })
    //     // }

    //     const files = Array.from(e.target.files)
    //     const images = []
    //     files.forEach((file) => {
    //         const reader = new FileReader()
    //         reader.readAsDataURL(file)
    //         reader.onload = async () => {
    //             // const base64String = reader.result.replace('data:', '').replace(/^.+,/, '')
    //             images.push({ image: reader.result })
    //         }
    //     })
    //     setForm((prev) => ({ ...prev, images }))

    //     // const formData = new FormData()
    //     // console.log(e.target.files)
    //     // setForm((prev) => {
    //     //     return {
    //     //         ...prev,
    //     //         // images: [...prev.images, e.target.files[0]],
    //     //         images: Array.from(e.target.files),
    //     //     }
    //     // })
    // }
    const handleImageChange = async (e) => {
        const files = Array.from(e.target.files)
        const imagePromises = files.map((file) => {
            return new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = () => {
                    resolve({ image: reader.result })
                }
                reader.readAsDataURL(file)
            })
        })

        const newImages = await Promise.all(imagePromises)

        setForm((prev) => ({
            ...prev,
            images: [...prev.images, ...newImages],
        }))
    }

    const getSpanEstimate = (size) => {
        if (size > 250) {
            return 2
        }

        return 1
    }
    const handleCancel = () => {
        setForm({                   
            additional_attributes: [],
            base_item_id: '123',
            is_available: true,
            is_inventory_item: true,
            is_service_item: true,
            market_price: 50,
            unit_of_issue: 100,
            documents: [],
            images: [],
        })
        navigate('/supplier/products')
    }

    return (
        <SupplierLayout active="products" title="Product Catelogue">
            {productCatgoriesQuery.isError ? (
                <Loader refresh />
            ) : productCatgoriesQuery.isLoading ? (
                <Loader />
            ) : (
                <form className={styles.AddProduct__container} onSubmit={handleSubmit} encType="multipart/form-data">
                    <h2 className={styles.AddProduct__title}>Add Product</h2>
                    <Card>
                        <div className={cx('row')}>
                            <div className={cx('col-6')}>
                                <div className={cx(styles['mb-4'])}>
                                    <InputWithError
                                        onChange={handleChange}
                                        name="name"
                                        label="Product Name"
                                        placeholder="Enter Product Name"
                                        type="text"
                                        value={form.name}
                                        errorMsg={formErrors.name}
                                    />
                                </div>

                                <div className={cx(styles['mb-4'])}>
                                    <FormSelect
                                        label="Product Category"
                                        name="product_category"
                                        options={productCatgoriesQuery.data}
                                        placeholder="Select Category"
                                        errorMsg={formErrors.product_category}
                                        value={form.product_category}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className={cx(styles['mb-4'])}>
                                    <FormSelect
                                        label="Product Sub Category"
                                        name="product_sub_category"
                                        options={productSubCategoriesQuery.data || {}}
                                        placeholder="Select Sub Category"
                                        errorMsg={formErrors.product_sub_category}
                                        value={form.product_sub_category}
                                        onChange={handleChange}
                                        isLoading={productSubCategoriesQuery.isLoading}
                                    />
                                </div>

                                <div className={cx(styles['mb-4'])}>
                                    <InputWithError
                                        onChange={handleChange}
                                        name="brand"
                                        label="Brand"
                                        placeholder="Enter Brand Name"
                                        type="text"
                                        value={form.brand}
                                        errorMsg={formErrors.brand}
                                    />
                                </div>
                            </div>
                            <div className={cx(styles['col-6'])}>
                                <div className={cx(styles['mb-4'])}>
                                    <div className={styles.AddProduct__imageWrapper}>
                                        {form?.images?.map((image) => {
                                            const style = {
                                                gridColumnEnd: `span ${getSpanEstimate(image.width)}`,
                                                gridRowEnd: `span ${getSpanEstimate(image.height)}`,
                                            }
                                            return (
                                                <img
                                                    className={styles.AddProduct__image}
                                                    style={style}
                                                    src={image.image}
                                                />
                                            )
                                        })}
                                    </div>
                                    <InputWithError
                                        onChange={handleImageChange}
                                        name="images"
                                        label="Product Images"
                                        type="file"
                                        multiple
                                    />
                                </div>
                                <div className={cx(styles['row'], styles['mb-4'])}>
                                    <div className={cx(styles['col-6'])}>
                                        <InputWithError
                                            onChange={handleChange}
                                            name="product_sku"
                                            label="Product SKU"
                                            placeholder="Enter Product SKU"
                                            type="text"
                                            value={form.product_sku}
                                            errorMsg={formErrors.product_sku}
                                        />
                                    </div>
                                    <div className={cx(styles['col-6'])}>
                                        <InputWithError
                                            onChange={handleChange}
                                            name="list_price_per_unit"
                                            label="Price Per Item"
                                            placeholder="Enter Price Per Item"
                                            type="text"
                                            value={form.list_price_per_unit}
                                            errorMsg={formErrors.list_price_per_unit}
                                        />
                                    </div>
                                    <div className={cx(styles['mb-4'])}>
                                        <FormTextarea
                                            onChange={handleChange}
                                            name="description"
                                            label="Description"
                                            placeholder=" Enter Description"
                                            type="text"
                                            value={form.description}
                                            errorMsg={formErrors.description}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.Btn__Container}>
                            <Button text="Cancel" className={styles.AddProduct__cancelButton} onClick={handleCancel} />
                            <Button text="Save" loading={submitMutation.isLoading} onClick={(e) => handleSubmit(e)} />
                        </div>
                    </Card>
                </form>
            )}
        </SupplierLayout>
    )
}

export default SupplierProductCatalogueAdd
