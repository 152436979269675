import React, { useState, useContext } from 'react'
import AuthContext from '../../context/AuthContext'
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import Button from '../../components/Button/Button'
import styles from './Templates.module.scss'
import { useNavigate } from 'react-router-dom'
import SupplierAPI from '../../api/SupplierAPI'
import { toast } from 'react-toastify'

const AddTemplate = () => {
    const [templateName, setTemplateName] = useState('')
    const [templateDescription, setTemplateDescription] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const { user } = useContext(AuthContext)

    const handleSave = async () => {
        setIsLoading(true)
        try {
            const response = await SupplierAPI.addTemplate(
                {
                    name: templateName,
                    description: templateDescription,
                },
                user.token
            )

            console.log('Template saved:', response.data)
            toast.success('Template added successfully')

            navigate('/supplier/organization/temp')

            setTemplateName('')
            setTemplateDescription('')
            setError('')
        } catch (err) {
            console.error('Error saving template:', err)
            setError('Error saving template. Please try again.')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <SupplierLayout active="organization" title="Templates">
            <div className={styles.container}>
                <div className={styles.addTemplateButton}>
                    <h2>Add Template</h2>
                </div>
                <div className={styles.form}>
                    <div className={styles.field}>
                        <label htmlFor="templateName" className={styles.label}>
                            Template Name:
                        </label>
                        <input
                            type="text"
                            className={styles.text}
                            id="templateName"
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                        />
                    </div>
                    <div className={styles.field}>
                        <label htmlFor="templateDescription" className={styles.labelDesc}>
                            Template Description:
                        </label>
                        <textarea
                            id="templateDescription"
                            className={styles.area}
                            value={templateDescription}
                            onChange={(e) => setTemplateDescription(e.target.value)}
                        />
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button text={isLoading ? 'Saving...' : 'Save'} onClick={handleSave} disabled={isLoading} />
                    </div>
                    {error && <p className={styles.error}>{error}</p>}
                </div>
            </div>
        </SupplierLayout>
    )
}

export default AddTemplate
