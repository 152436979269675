import React, { useState, useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import SupplierAPI from '../../api/SupplierAPI'
import AuthContext from '../../context/AuthContext'
import Loader from '../../components/Loader/Loader'
import Card from '../../components/Card/Card'
import styles from './EnquiryNotification.module.scss'
import SupplierLayout from '../../components/SupplierLayout/SupplierLayout'
import Button from '../../components/Button/Button'
import { toast } from 'react-toastify'
import Filter from '../../components/Filter/Filter'
import { useParams } from 'react-router-dom'

const EnquiryNotification = () => {
    const { organisationId } = useParams()
    const { user } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const [actionInProgress, setActionInProgress] = useState({})
    const [filter, setFilter] = useState('All')
    console.log('Organisation ID:', organisationId)

    const { data, isLoading, isError, error, refetch } = useQuery(
        ['enquiries', organisationId],
        async () => {
            const response = await SupplierAPI.getProductEnquiry({ token: user.token, id: organisationId })
            return response
        },
        {
            enabled: !!organisationId,
            onError: (err) => {
                console.error('Query Error:', err)
            },
        }
    )

    const handleFilterChange = (value) => setFilter(value)

    const handleAcknowledge = async (enquiryId) => {
        setLoading(true)
        setActionInProgress((prev) => ({ ...prev, [enquiryId]: 'acknowledge' }))
        try {
            await SupplierAPI.acknowledgeEnquiry({ token: user.token, id: enquiryId })
            toast.success('Enquiry acknowledged!')
            refetch()
        } catch (error) {
            console.error('Error acknowledging enquiry:', error)
            toast.error('Enquiry acknowledgement failed!')
        } finally {
            setLoading(false)
            setActionInProgress((prev) => ({ ...prev, [enquiryId]: null }))
        }
    }

    const handleDelete = async (enquiryId) => {
        setLoading(true)
        setActionInProgress((prev) => ({ ...prev, [enquiryId]: 'delete' }))
        try {
            await SupplierAPI.deleteEnquiry({ token: user.token, id: enquiryId })
            toast.success('Enquiry rejected!')
            refetch()
        } catch (error) {
            console.error('Error rejecting enquiry:', error)
            toast.error('Failed to reject the enquiry!')
        } finally {
            setLoading(false)
            setActionInProgress((prev) => ({ ...prev, [enquiryId]: null }))
        }
    }

    if (isLoading) return <Loader />
    if (isError && error.response?.status === 404) {
        return (
            <SupplierLayout title="Enquiries" active="products">
                <p className={styles.ErrorMessage}>Enquiries not found for this organisation.</p>
            </SupplierLayout>
        )
    }
    if (isError) return <p>Error loading enquiries.</p>

    const normalizedFilter = filter.toLowerCase()

    const sortedEnquiries = data?.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

    const filteredEnquiries = sortedEnquiries?.filter((enquiry) => {
        const status = enquiry.int_status
        if (normalizedFilter === 'acknowledged') return status === 1
        if (normalizedFilter === 'rejected') return status === -1
        return true
    })

    return (
        <SupplierLayout title="Enquiries" active="products">
            <div className={styles.FilterContainer}>
                <Filter filter={filter} options={['All', 'Acknowledged', 'Rejected']} onChange={handleFilterChange} />
            </div>
            <div className={styles.EnquiryList}>
                {filteredEnquiries?.length > 0 ? (
                    <div className={styles.EnquiryCards}>
                        {filteredEnquiries.map((enquiry) => {
                            const isAcknowledged = enquiry.int_status === 1
                            const isDeleted = enquiry.int_status === -1
                            return (
                                <Card key={enquiry.id} className={styles.EnquiryCard}>
                                    <div className={styles.EnquiryCard__Details}>
                                        <p className={styles.EnquiryCard__title}>
                                            Organization: {enquiry.organisation_name}
                                        </p>
                                        <p className={styles.EnquiryCard__note}>
                                            <span className={styles.EnquiryCard__note__main}>Email:</span>
                                            <span className={styles.EnquiryCard__note__sub}>{enquiry.email}</span>
                                        </p>
                                        <p className={styles.EnquiryCard__note}>
                                            <span className={styles.EnquiryCard__note__main}>Date:</span>
                                            <span className={styles.EnquiryCard__note__sub}>
                                                {new Date(enquiry.created_at).toLocaleDateString()}
                                            </span>
                                        </p>
                                        <div className={styles.EnquiryCard__products}>
                                            <p className={styles.EnquiryCard__products__title}>Products:</p>
                                            <ul>
                                                {enquiry.items.map((item, index) => (
                                                    <li key={index} className={styles.EnquiryCard__products__item}>
                                                        <p>
                                                            <b>Product Name:</b> {item.product.name}
                                                        </p>
                                                        <p>
                                                            <b>Quantity:</b> {item.quantity}
                                                        </p>
                                                    </li>
                                                ))}
                                            </ul>
                                            <div className={styles.Btn}>
                                                {isAcknowledged ? (
                                                    <Button text="Acknowledged" disabled />
                                                ) : isDeleted ? (
                                                    <Button text="Rejected" disabled />
                                                ) : (
                                                    <>
                                                        <Button
                                                            text={
                                                                loading &&
                                                                actionInProgress[enquiry.id] === 'acknowledge'
                                                                    ? 'Acknowledging...'
                                                                    : 'Acknowledge'
                                                            }
                                                            onClick={() => handleAcknowledge(enquiry.id)}
                                                            disabled={loading && actionInProgress[enquiry.id]}
                                                        />
                                                        <Button
                                                            className={styles.Delete_btn}
                                                            text={
                                                                loading && actionInProgress[enquiry.id] === 'delete'
                                                                    ? 'Rejecting...'
                                                                    : 'Reject'
                                                            }
                                                            onClick={() => handleDelete(enquiry.id)}
                                                            disabled={loading && actionInProgress[enquiry.id]}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            )
                        })}
                    </div>
                ) : (
                    <p>No enquiries found.</p>
                )}
            </div>
        </SupplierLayout>
    )
}

export default EnquiryNotification
